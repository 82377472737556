<template>
  <LoadingModalFullScreen
    ref="loadingModal"
    text="PREPARING YOUR SEARCH"
    @close="close"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'

export default {
  name: 'SearchStream',
  components: { LoadingModalFullScreen },
  created() {
    this.init()
  },
  mounted() {
    this.$refs.loadingModal.show()
  },
  data() {
    return {
      closed: false,
    }
  },
  computed: mapGetters('SearchForm', ['parseQueryParameters']),
  methods: {
    close() {
      this.closed = true
      this.$router.replace('/search')
    },
    ...mapMutations('SearchForm', ['setParams']),
    ...mapActions('SearchForm', ['resolveParameters']),
    async init() {
      const params = this.parseQueryParameters(this.$route.query)
      const result = await this.resolveParameters({
        params,
        resolveAsync: true,
      })
      if (this.closed) return
      this.setParams(result.params)
      if (result.valid)
        this.$router.replace({ path: '/search/q', query: result.params })
      else this.$router.replace('/search/form')
    },
  },
}
</script>

<style scoped lang="scss"></style>
